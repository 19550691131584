.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  width : 150px;
  height : 190px;
  margin : 10px 15px;
  vertical-align: middle;
  /*display: inline-block;*/
  background-position: center
}

.logo .hidden {
  visibility: hidden;
  vertical-align: center;
  margin:auto;
}

.logo:hover {
  background: transparent;
}

.logo:hover .hidden {
  visibility: visible;
}

.card {
  display: inline-flex !important;
  border: none !important;
}

.gaiax { background: url('./img/gaiax.gif') no-repeat 0 0; background-position: center; width: 100%; height: 100px;}
.M2RzLW91dHNjYWxlLnBuZw { background: url('./img/3ds-outscale.png') no-repeat 0 0; background-position: center; }
.ZGUtY2l4LnBuZw { background: url('./img/de-cix.png') no-repeat 0 0; background-position: center; }
.Ym13LWdyb3VwLnBuZw { background: url('./img/bmw-group.png') no-repeat 0 0; background-position: center; }
.ZGV1dHNjaGUtdGVsZWtvbS1hZy5wbmc { background: url('./img/deutsche-telekom-ag.png') no-repeat 0 0; background-position: center; }
.cm9iZXJ0LWJvc2NoLWdtYmgucG5n { background: url('./img/robert-bosch-gmbh.png') no-repeat 0 0; background-position: center; }
.Z2VybWFuLWVkZ2UtY2xvdWQtZ21iaC1jby1rZy5wbmc { background: url('./img/german-edge-cloud-gmbh-co-kg.png') no-repeat 0 0; background-position: center; }
.b3ZoLXNhcy5wbmc { background: url('./img/ovh-sas.png') no-repeat 0 0; background-position: center; }
.c2llbWVucy1hZy5wbmc { background: url('./img/siemens-ag.png') no-repeat 0 0; background-position: center; }
.Y2xvdWQtaW5mcmFzdHJ1Y3R1cmUtc2VydmljZXMtcHJvdmlkZXJzLWluLWV1cm9wZS1jaXNwZS1haXNibC5wbmc { background: url('./img/cloud-infrastructure-services-providers-in-europe-cispe-aisbl.png') no-repeat 0 0; background-position: center; }
.c2NhbGV3YXktdGhlLWNsb3VkLXRoYXQtbWFrZXMtc2Vuc2UucG5n { background: url('./img/scaleway-the-cloud-that-makes-sense.png') no-repeat 0 0; background-position: center; }
.c2FwLXNlLnBuZw { background: url('./img/sap-se.png') no-repeat 0 0; background-position: center; }
.YmVja2hvZmYtYXV0b21hdGlvbi1nbWJoLWNvLWtnLnBuZw { background: url('./img/beckhoff-automation-gmbh-co-kg.png') no-repeat 0 0; background-position: center; }
.ZG9jYXBvc3RlLXNhcy5wbmc { background: url('./img/docaposte-sas.png') no-repeat 0 0; background-position: center; }
.b3JhbmdlLnBuZw { background: url('./img/orange.png') no-repeat 0 0; background-position: center; }
.cGx1c3NlcnZlci1nbWJoLnBuZw { background: url('./img/plusserver-gmbh.png') no-repeat 0 0; background-position: center; }
.YW1hZGV1cy1zYXMucG5n { background: url('./img/amadeus-sas.png') no-repeat 0 0; background-position: center; }
.YXRvcy1zZS5wbmc { background: url('./img/atos-se.png') no-repeat 0 0; background-position: center; }
.c2FmcmFuLnBuZw { background: url('./img/safran.png') no-repeat 0 0; background-position: center; }
.aW5zdGl0dXQtbWluZXMtdGVsZWNvbS1lcHNjcC5wbmc { background: url('./img/institut-mines-telecom-epscp.png') no-repeat 0 0; background-position: center; }
.ZnJhdW5ob2Zlci1nZXNlbGxzY2hhZnQtenVyLWZvcmRlcnVuZy1kZXItYW5nZXdhbmR0ZW4tZm9yc2NodW5nLWUtdi5wbmc { background: url('./img/fraunhofer-gesellschaft-zur-forderung-der-angewandten-forschung-e-v.png') no-repeat 0 0; background-position: center; }
.aW50ZXJuYXRpb25hbC1kYXRhLXNwYWNlcy1hc3NvY2lhdGlvbi5wbmc { background: url('./img/international-data-spaces-association.png') no-repeat 0 0; background-position: center; }
.ZWxlY3RyaWNpdGUtZGUtZnJhbmNlLWVkZi1zYS5wbmc { background: url('./img/electricite-de-france-edf-sa.png') no-repeat 0 0; background-position: center; }
.dGhlLWxvbmRvbi1pbnRlcm5ldC1leGNoYW5nZS1saW54LnBuZw { background: url('./img/the-london-internet-exchange-linx.png') no-repeat 0 0; background-position: center; }
.MS0xLWlvbm9zLXNlLnBuZw { background: url('./img/1-1-ionos-se.png') no-repeat 0 0; background-position: center; }
.b3Blbi14Y2hhbmdlLWFnLnBuZw { background: url('./img/open-xchange-ag.png') no-repeat 0 0; background-position: center; }
.YWxiZXJ0by1naWFjY29uZS5wbmc { background: url('./img/alberto-giaccone.png') no-repeat 0 0; background-position: center; }
.YXJ1YmEtcy1wLWEucG5n { background: url('./img/aruba-s-p-a.png') no-repeat 0 0; background-position: center; }
.c2Nod2Fyei1pdC1rZy1zY2h3YXJ6LWdydXBwZS5wbmc { background: url('./img/schwarz-it-kg-schwarz-gruppe.png') no-repeat 0 0; background-position: center; }
.cGFsYW50aXItdGVjaG5vbG9naWVzLWluYy5wbmc { background: url('./img/palantir-technologies-inc.png') no-repeat 0 0; background-position: center; }
.ZGlnaXRhbGV1cm9wZS5wbmc { background: url('./img/digitaleurope.png') no-repeat 0 0; background-position: center; }
.cHJvaml4aS1ldXJvcGUucG5n { background: url('./img/projixi-europe.png') no-repeat 0 0; background-position: center; }
.YXNzb3NvZnR3YXJlLnBuZw { background: url('./img/assosoftware.png') no-repeat 0 0; background-position: center; }
.bWlwaWgtZ2lwLXB1YmxpYy1pbnRlcmVzdC1ncm91cC5wbmc { background: url('./img/mipih-gip-public-interest-group.png') no-repeat 0 0; background-position: center; }
.c2lhdi1zLXAtYS5wbmc { background: url('./img/siav-s-p-a.png') no-repeat 0 0; background-position: center; }
.Y29uc3RlbGxhdGlvbi5wbmc { background: url('./img/constellation.png') no-repeat 0 0; background-position: center; }
.c2NoZWVyLWdtYmgucG5n { background: url('./img/scheer-gmbh.png') no-repeat 0 0; background-position: center; }
.b3Blbm5lYnVsYS5wbmc { background: url('./img/opennebula.png') no-repeat 0 0; background-position: center; }
.dm9sa3N3YWdlbi1ha3RpZW5nZXNlbGxzY2hhZnQucG5n { background: url('./img/volkswagen-aktiengesellschaft.png') no-repeat 0 0; background-position: center; }
.YWdvcmlhLnBuZw { background: url('./img/agoria.png') no-repeat 0 0; background-position: center; }
.ZGVkYWdyb3VwLnBuZw { background: url('./img/dedagroup.png') no-repeat 0 0; background-position: center; }
.aXJ0LXNhaW50LWV4dXBlcnkucG5n { background: url('./img/irt-saint-exupery.png') no-repeat 0 0; background-position: center; }
.Y2xvdWQtaGVhdC10ZWNobm9sb2dpZXMtZ21iaC5wbmc { background: url('./img/cloud-heat-technologies-gmbh.png') no-repeat 0 0; background-position: center; }
.cm95YWwtcGhpbGlwcy5wbmc { background: url('./img/royal-philips.png') no-repeat 0 0; background-position: center; }
.c3lzdGVtYXRpYy1wYXJpcy1yZWdpb24uanBn { background: url('./img/systematic-paris-region.jpg') no-repeat 0 0; background-position: center; }
.bmljb3MtYWcucG5n { background: url('./img/nicos-ag.png') no-repeat 0 0; background-position: center; }
.dGltLXMtcC1hLnBuZw { background: url('./img/tim-s-p-a.png') no-repeat 0 0; background-position: center; }
.c25vd2ZsYWtlLnBuZw { background: url('./img/snowflake.png') no-repeat 0 0; background-position: center; }
.Zml3YXJlLWZvdW5kYXRpb24tZS12LnBuZw { background: url('./img/fiware-foundation-e-v.png') no-repeat 0 0; background-position: center; }
.YWxsaWFuY2UtZm9yLWludGVybmV0LW9mLXRoaW5ncy1pbm5vdmF0aW9uLWFpc2JsLWFpb3RvLnBuZw { background: url('./img/alliance-for-internet-of-things-innovation-aisbl-aioto.png') no-repeat 0 0; background-position: center; }
.c2VjdW5ldC5wbmc { background: url('./img/secunet.png') no-repeat 0 0; background-position: center; }
.bXlyYS1zZWN1cml0eS1nbWJoLnBuZw { background: url('./img/myra-security-gmbh.png') no-repeat 0 0; background-position: center; }
.c3lzdG5hcHMtc2FzLnBuZw { background: url('./img/systnaps-sas.png') no-repeat 0 0; background-position: center; }
.eHdpa2ktc2FzLnBuZw { background: url('./img/xwiki-sas.png') no-repeat 0 0; background-position: center; }
.dGhhbGVzLXNlcnZpY2VzLW51bWVyaXF1ZXMucG5n { background: url('./img/thales-services-numeriques.png') no-repeat 0 0; background-position: center; }
.b3duY2xvdWQtZ21iaC5wbmc { background: url('./img/owncloud-gmbh.png') no-repeat 0 0; background-position: center; }
.aG9zcGl0YWwtY2xpbmljLWRlLWJhcmNlbG9uYS5qcGVn { background: url('./img/hospital-clinic-de-barcelona.jpeg') no-repeat 0 0; background-position: center; }
.ZmFiYXNvZnQucG5n { background: url('./img/fabasoft.png') no-repeat 0 0; background-position: center; }
.c29nZWktcy1wLWEucG5n { background: url('./img/sogei-s-p-a.png') no-repeat 0 0; background-position: center; }
.Y2FwZ2VtaW5pLWNvbnN1bHRpbmcucG5n { background: url('./img/capgemini-consulting.png') no-repeat 0 0; background-position: center; }
.c2FsZXNmb3JjZS1jb20taW5jLnBuZw { background: url('./img/salesforce-com-inc.png') no-repeat 0 0; background-position: center; }
.YnVuZGVzdmVyYmFuZC1kZXItZGV1dHNjaGVuLWluZHVzdHJpZS1lLXYucG5n { background: url('./img/bundesverband-der-deutschen-industrie-e-v.png') no-repeat 0 0; background-position: center; }
.cG9zdGUtaXRhbGlhbmUtcy1wLWEucG5n { background: url('./img/poste-italiane-s-p-a.png') no-repeat 0 0; background-position: center; }
.ZXVzdGVtYS1zLXAtYS5wbmc { background: url('./img/eustema-s-p-a.png') no-repeat 0 0; background-position: center; }
.YWk0YmQtZGV1dHNjaGxhbmQtZ21iaC5wbmc { background: url('./img/ai4bd-deutschland-gmbh.png') no-repeat 0 0; background-position: center; }
.bGVvbmFyZG8tcy1wLWEuanBn { background: url('./img/leonardo-s-p-a.jpg') no-repeat 0 0; background-position: center; }
.a2xhcnJpby1idi5wbmc { background: url('./img/klarrio-bv.png') no-repeat 0 0; background-position: center; }
.c2FzLWNsZXZlci1jbG91ZC5wbmc { background: url('./img/sas-clever-cloud.png') no-repeat 0 0; background-position: center; }
.bmV0bm9kLWludGVybmV0LWV4Y2hhbmdlLWktc3ZlcmlnZS1hYi5wbmc { background: url('./img/netnod-internet-exchange-i-sverige-ab.png') no-repeat 0 0; background-position: center; }
.aXJpZGVvcy1zLXAtYS5wbmc { background: url('./img/irideos-s-p-a.png') no-repeat 0 0; background-position: center; }
.Z2lnYXMtaG9zdGluZy1zYS5wbmc { background: url('./img/gigas-hosting-sa.png') no-repeat 0 0; background-position: center; }
.cGxhdGZvcm0tc2gucG5n { background: url('./img/platform-sh.png') no-repeat 0 0; background-position: center; }
.ZGF3ZXgucG5n { background: url('./img/dawex.png') no-repeat 0 0; background-position: center; }
.bXNnLnBuZw { background: url('./img/msg.png') no-repeat 0 0; background-position: center; }
.bWF0ZXJuYS1pbmZvcm1hdGlvbi1jb21tdW5pY2F0aW9ucy1zZS5wbmc { background: url('./img/materna-information-communications-se.png') no-repeat 0 0; background-position: center; }
.cmVhbC1jb21tLXNybC5wbmc { background: url('./img/real-comm-srl.png') no-repeat 0 0; background-position: center; }
.cmFwaWQtc3BhY2UtaW50ZXJuYXRpb25hbC5wbmc { background: url('./img/rapid-space-international.png') no-repeat 0 0; background-position: center; }
.Y3NjLWl0LWNlbnRlci1mb3Itc2NpZW5jZS1sdGQucG5n { background: url('./img/csc-it-center-for-science-ltd.png') no-repeat 0 0; background-position: center; }
.bWluaXN0ZXJlLWRlLWwtZWR1Y2F0aW9uLW5hdGlvbmFsZS1kZS1sYS1qZXVuZXNzZS1ldC1kZXMtc3BvcnRzLmpwZw { background: url('./img/ministere-de-l-education-nationale-de-la-jeunesse-et-des-sports.jpg') no-repeat 0 0; background-position: center; }
.dW5pdmVyc2l0ZWl0LXZhbi1hbXN0ZXJkYW0ucG5n { background: url('./img/universiteit-van-amsterdam.png') no-repeat 0 0; background-position: center; }
.bWFpZi5wbmc { background: url('./img/maif.png') no-repeat 0 0; background-position: center; }
.YW1hem9uLWV1cm9wZS1jb3JlLXMtYS1yLWwucG5n { background: url('./img/amazon-europe-core-s-a-r-l.png') no-repeat 0 0; background-position: center; }
.aW5uby1mb2N1cy1idXNpbmVzc2NvbnN1bHRpbmctZ21iaC5wbmc { background: url('./img/inno-focus-businessconsulting-gmbh.png') no-repeat 0 0; background-position: center; }
.YmV0YWNsb3VkLXNvbHV0aW9ucy1nbWJoLnBuZw { background: url('./img/betacloud-solutions-gmbh.png') no-repeat 0 0; background-position: center; }
.YXNvY2lhY2lvbi1pbm5vdmFsaWEucG5n { background: url('./img/asociacion-innovalia.png') no-repeat 0 0; background-position: center; }
.c25jZi1zYS5wbmc { background: url('./img/sncf-sa.png') no-repeat 0 0; background-position: center; }
.aHVhd2VpLXRlY2hub2xvZ2llcy1kdWVzc2VsZG9yZi1nbWJoLnBuZw { background: url('./img/huawei-technologies-duesseldorf-gmbh.png') no-repeat 0 0; background-position: center; }
.bmV4dGNsb3VkLWdtYmgucG5n { background: url('./img/nextcloud-gmbh.png') no-repeat 0 0; background-position: center; }
.Y2Fpc3NlLWRlcy1kZXBvdHMtZXQtY29uc2lnbmF0aW9uc2ZyYW5jZS5qcGc { background: url('./img/caisse-des-depots-et-consignationsfrance.jpg') no-repeat 0 0; background-position: center; }
.Y29uZmluZHVzdHJpYS1kaWdpdGFsZS5wbmc { background: url('./img/confindustria-digitale.png') no-repeat 0 0; background-position: center; }
.dmFzdHV1LWdyb3VwLnBuZw { background: url('./img/vastuu-group.png') no-repeat 0 0; background-position: center; }
.c3lzZWxldmVuLWdtYmgucG5n { background: url('./img/syseleven-gmbh.png') no-repeat 0 0; background-position: center; }
.ZXVyb3BlYW4tY29tbWl0dGVlLWZvci1pbnRlcm9wZXJhYmxlLXN5c3RlbXMtZWNpcy5qcGc { background: url('./img/european-committee-for-interoperable-systems-ecis.jpg') no-repeat 0 0; background-position: center; }
.ZXVyb3BhY2FibGUtYWlzYmwucG5n { background: url('./img/europacable-aisbl.png') no-repeat 0 0; background-position: center; }
.dnR0LXRlY2huaWNhbC1yZXNlYXJjaC1jZW50cmUtb2YtZmlubGFuZC5wbmc { background: url('./img/vtt-technical-research-centre-of-finland.png') no-repeat 0 0; background-position: center; }
.Yml0a29tLWUtdi5wbmc { background: url('./img/bitkom-e-v.png') no-repeat 0 0; background-position: center; }
.d2VzdHBvbGUtcy1wLWEucG5n { background: url('./img/westpole-s-p-a.png') no-repeat 0 0; background-position: center; }
.dGFzLXN0ZXZlbi5wbmc { background: url('./img/tas-steven.png') no-repeat 0 0; background-position: center; }
.bWFudWZhY3R1cmUtZnJhbmNhaXNlLWRlcy1wbmV1cy1taWNoZWxpbi5qcGc { background: url('./img/manufacture-francaise-des-pneus-michelin.jpg') no-repeat 0 0; background-position: center; }
.ZGVlcHNob3JlLWdtYmgucG5n { background: url('./img/deepshore-gmbh.png') no-repeat 0 0; background-position: center; }
.Y2VmcmllbC1zLWNvbnMtci1sLnBuZw { background: url('./img/cefriel-s-cons-r-l.png') no-repeat 0 0; background-position: center; }
.c3lzdGVteC5wbmc { background: url('./img/systemx.png') no-repeat 0 0; background-position: center; }
.YWlyYnVzLXNhcy5wbmc { background: url('./img/airbus-sas.png') no-repeat 0 0; background-position: center; }
.c2VhZ2F0ZS1zeXN0ZW1zLWlyZWxhbmQtbHRkLmpwZw { background: url('./img/seagate-systems-ireland-ltd.jpg') no-repeat 0 0; background-position: center; }
.cmVwbHkucG5n { background: url('./img/reply.png') no-repeat 0 0; background-position: center; }
.bGluYWdvcmEucG5n { background: url('./img/linagora.png') no-repeat 0 0; background-position: center; }
.Z29vZ2xlLWNsb3VkLnBuZw { background: url('./img/google-cloud.png') no-repeat 0 0; background-position: center; }
.aWJtLWJlbGdpdW0tYnZiYS1zcHJsLnBuZw { background: url('./img/ibm-belgium-bvba-sprl.png') no-repeat 0 0; background-position: center; }
.bW91dmVtZW50LWRlcy1lbnRyZXByaXNlcy1kZS1mcmFuY2UtbWVkZWYuanBn { background: url('./img/mouvement-des-entreprises-de-france-medef.jpg') no-repeat 0 0; background-position: center; }
.cHJvdmVucnVuLnBuZw { background: url('./img/provenrun.png') no-repeat 0 0; background-position: center; }
.c3VwcGx5b24tYWcucG5n { background: url('./img/supplyon-ag.png') no-repeat 0 0; background-position: center; }
.dmFyLWdyb3VwLnBuZw { background: url('./img/var-group.png') no-repeat 0 0; background-position: center; }
.ZGV1dHNjaGUtYmFuay1hZy5wbmc { background: url('./img/deutsche-bank-ag.png') no-repeat 0 0; background-position: center; }
.c2VsYnN0cmVndWxpZXJ1bmctaW5mb3JtYXRpb25zd2lydHNjaGFmdC1lLXYtc3Jpdy5wbmc { background: url('./img/selbstregulierung-informationswirtschaft-e-v-sriw.png') no-repeat 0 0; background-position: center; }
.b3JhY2xlLWNvcnBvcmF0aW9uLnBuZw { background: url('./img/oracle-corporation.png') no-repeat 0 0; background-position: center; }
.aW50ZXNhLXNhbnBhb2xvLnBuZw { background: url('./img/intesa-sanpaolo.png') no-repeat 0 0; background-position: center; }
.ZGVuaWMtZWcucG5n { background: url('./img/denic-eg.png') no-repeat 0 0; background-position: center; }
.dG9wLWl4LnBuZw { background: url('./img/top-ix.png') no-repeat 0 0; background-position: center; }
.cWJlZS1hcy5wbmc { background: url('./img/qbee-as.png') no-repeat 0 0; background-position: center; }
.enZlaS16ZW50cmFsdmVyYmFuZC1lbGVrdHJvdGVjaG5pay11bmQtZWxla3Ryb25pa2luZHVzdHJpZS1lLXYucG5n { background: url('./img/zvei-zentralverband-elektrotechnik-und-elektronikindustrie-e-v.png') no-repeat 0 0; background-position: center; }
.ZXJpY3Nzb24ucG5n { background: url('./img/ericsson.png') no-repeat 0 0; background-position: center; }
.c2Vuc2VlcmluZy1nbWJoLnBuZw { background: url('./img/senseering-gmbh.png') no-repeat 0 0; background-position: center; }
.NGNvbS1nbWJoLWNvLWtnLnBuZw { background: url('./img/4com-gmbh-co-kg.png') no-repeat 0 0; background-position: center; }
.Y3JlZGl0LWFncmljb2xlLXNhLnBuZw { background: url('./img/credit-agricole-sa.png') no-repeat 0 0; background-position: center; }
.aG9zdGV1ci5wbmc { background: url('./img/hosteur.png') no-repeat 0 0; background-position: center; }
.Ym90bGFicy1nbWJoLnBuZw { background: url('./img/botlabs-gmbh.png') no-repeat 0 0; background-position: center; }
.aGlnaHEtY29tcHV0ZXJsb3N1bmdlbi1nbWJoLnBuZw { background: url('./img/highq-computerlosungen-gmbh.png') no-repeat 0 0; background-position: center; }
.cmV0ZWxpdC1zLXAtYS5wbmc { background: url('./img/retelit-s-p-a.png') no-repeat 0 0; background-position: center; }
.Yml0NGlkLXNybC5wbmc { background: url('./img/bit4id-srl.png') no-repeat 0 0; background-position: center; }
.YjEtc3lzdGVtcy1nbWJoLnBuZw { background: url('./img/b1-systems-gmbh.png') no-repeat 0 0; background-position: center; }
.ZW5naWUtc2EucG5n { background: url('./img/engie-sa.png') no-repeat 0 0; background-position: center; }
.Y2NleC1jbG91ZC1jb21tb2RpdGllcy1leGNoYW5nZS1nbWJoLnBuZw { background: url('./img/ccex-cloud-commodities-exchange-gmbh.png') no-repeat 0 0; background-position: center; }
.YmlnY2hhaW5kYi1nbWJoLnBuZw { background: url('./img/bigchaindb-gmbh.png') no-repeat 0 0; background-position: center; }
.ZXVyb2Nsb3VkLWRldXRzY2hsYW5kLWVjby1lLXYucG5n { background: url('./img/eurocloud-deutschland-eco-e-v.png') no-repeat 0 0; background-position: center; }
.ZnVqaXRzdS5wbmc { background: url('./img/fujitsu.png') no-repeat 0 0; background-position: center; }
.Y29uZmluZHVzdHJpYS1zZXJ2aXppLWlubm92YXRpdmktZS10ZWNub2xvZ2ljaS5wbmc { background: url('./img/confindustria-servizi-innovativi-e-tecnologici.png') no-repeat 0 0; background-position: center; }
.YnBjZS1zYS5qcGc { background: url('./img/bpce-sa.jpg') no-repeat 0 0; background-position: center; }
.bWljcm9zb2Z0LW52LnBuZw { background: url('./img/microsoft-nv.png') no-repeat 0 0; background-position: center; }
.dG5vLnBuZw { background: url('./img/tno.png') no-repeat 0 0; background-position: center; }
.cmhlYS1zeXN0ZW0tcy1hLnBuZw { background: url('./img/rhea-system-s-a.png') no-repeat 0 0; background-position: center; }
.dGVsZWtvbS1hdXN0cmlhLWFnLnBuZw { background: url('./img/telekom-austria-ag.png') no-repeat 0 0; background-position: center; }
.Ym5wLXBhcmliYXMucG5n { background: url('./img/bnp-paribas.png') no-repeat 0 0; background-position: center; }
.ZWdpLWZlZGVyYXRpb24ucG5n { background: url('./img/egi-federation.png') no-repeat 0 0; background-position: center; }
.b3BlbnVrLnBuZw { background: url('./img/openuk.png') no-repeat 0 0; background-position: center; }
.dGhyZWVmb2xkLXRlY2gucG5n { background: url('./img/threefold-tech.png') no-repeat 0 0; background-position: center; }
.Y2lzY28tc3lzdGVtcy1iZWxnaXVtLWJ2LnBuZw { background: url('./img/cisco-systems-belgium-bv.png') no-repeat 0 0; background-position: center; }
.ZXRuby1haXNibC5wbmc { background: url('./img/etno-aisbl.png') no-repeat 0 0; background-position: center; }
.dm13YXJlLWludGVybmF0aW9uYWwtdW5saW1pdGVkLWNvbXBhbnkucG5n { background: url('./img/vmware-international-unlimited-company.png') no-repeat 0 0; background-position: center; }
.ZW5lbC1nbG9iYWwtc2VydmljZXMtcy1yLWwucG5n { background: url('./img/enel-global-services-s-r-l.png') no-repeat 0 0; background-position: center; }
.aGV3bGV0dC1wYWNrYXJkLWVudGVycHJpc2UucG5n { background: url('./img/hewlett-packard-enterprise.png') no-repeat 0 0; background-position: center; }
.YWxpYmFiYS1jbG91ZC1pbnRlbGxpZ2VuY2UucG5n { background: url('./img/alibaba-cloud-intelligence.png') no-repeat 0 0; background-position: center; }
.ZWJyYy1zLWEuanBn { background: url('./img/ebrc-s-a.jpg') no-repeat 0 0; background-position: center; }
.ZGF0ZXYtZWcucG5n { background: url('./img/datev-eg.png') no-repeat 0 0; background-position: center; }
.dmRtYS1lLXYuanBlZw { background: url('./img/vdma-e-v.jpeg') no-repeat 0 0; background-position: center; }
.aWN0LWF1c3RyaWEtY2VudGVyLWZvci1idXNpbmVzcy10ZWNobm9sb2d5dmVyZWluLWZ1ci1vc3RlcnJlaWNoaXNjaGUtaWt0LXVudGVybmVobWVuLnBuZw { background: url('./img/ict-austria-center-for-business-technologyverein-fur-osterreichische-ikt-unternehmen.png') no-repeat 0 0; background-position: center; }
.YXBpaWRhLWFnLnBuZw { background: url('./img/apiida-ag.png') no-repeat 0 0; background-position: center; }
.YWNjZW50dXJlLWdsb2JhbC1zb2x1dGlvbnMtbHRkLnBuZw { background: url('./img/accenture-global-solutions-ltd.png') no-repeat 0 0; background-position: center; }
.ZHJhY29vbi1nbWJoLnBuZw { background: url('./img/dracoon-gmbh.png') no-repeat 0 0; background-position: center; }
.Zm9ydGlzcy1nbWJoLnBuZw { background: url('./img/fortiss-gmbh.png') no-repeat 0 0; background-position: center; }
.ZWNvLWFzc29jaWF0aW9uLW9mLWhlLWludGVybmV0LWluZHVzdHJ5LnBuZw { background: url('./img/eco-association-of-he-internet-industry.png') no-repeat 0 0; background-position: center; }
.YWRvYmlzLWdyb3VwLnBuZw { background: url('./img/adobis-group.png') no-repeat 0 0; background-position: center; }
.aW50ZWwtY29ycG9yYXRpb24ucG5n { background: url('./img/intel-corporation.png') no-repeat 0 0; background-position: center; }
.b2RuLWdtYmgtY28ta2cucG5n { background: url('./img/odn-gmbh-co-kg.png') no-repeat 0 0; background-position: center; }
.ZXVyb2xpbmsucG5n { background: url('./img/eurolink.png') no-repeat 0 0; background-position: center; }
.b21uaXMtY2xvdWQtcy1hLXItbC5wbmc { background: url('./img/omnis-cloud-s-a-r-l.png') no-repeat 0 0; background-position: center; }
.ZXhhbWVzaC1nbWJoLnBuZw { background: url('./img/examesh-gmbh.png') no-repeat 0 0; background-position: center; }
.ZmFzdG5ldC1zLXAtYS5wbmc { background: url('./img/fastnet-s-p-a.png') no-repeat 0 0; background-position: center; }
.YWdkYXRhaHViLXNhcy5wbmc { background: url('./img/agdatahub-sas.png') no-repeat 0 0; background-position: center; }
.a29tcGV0ZW56bmV0endlcmstdHJ1c3RlZC1jbG91ZC1lLXYucG5n { background: url('./img/kompetenznetzwerk-trusted-cloud-e-v.png') no-repeat 0 0; background-position: center; }
.aW5zZW50aXMtZ21iaC5wbmc { background: url('./img/insentis-gmbh.png') no-repeat 0 0; background-position: center; }
.ZW5naW5lZXJpbmctaW5nZWduZXJpYS1pbmZvcm1hdGljYS5wbmc { background: url('./img/engineering-ingegneria-informatica.png') no-repeat 0 0; background-position: center; }
.ZWNvbGUtbm9ybWFsZS1zdXBlcmlldXJlLXBhcmlzLXNhY2xheS1lbnMtcGFyaXMtc2FjbGF5LnBuZw { background: url('./img/ecole-normale-superieure-paris-saclay-ens-paris-saclay.png') no-repeat 0 0; background-position: center; }
.d2VtZWRvby1hZy5wbmc { background: url('./img/wemedoo-ag.png') no-repeat 0 0; background-position: center; }
.Y3MtZ3JvdXAucG5n { background: url('./img/cs-group.png') no-repeat 0 0; background-position: center; }
.bGludXAtc3JsLnBuZw { background: url('./img/linup-srl.png') no-repeat 0 0; background-position: center; }
.bmV0YWxpYS1zLXItbC5wbmc { background: url('./img/netalia-s-r-l.png') no-repeat 0 0; background-position: center; }
.aXRpLWluc3RpdHV0by10ZWNub2xvZ2ljby1kZS1pbmZvcm1hdGljYS5wbmc { background: url('./img/iti-instituto-tecnologico-de-informatica.png') no-repeat 0 0; background-position: center; }
.aXAtdGVsZWNvbS1zZXJ2aWNvcy1kZS10ZWxlY29tdW5pY2Fjb2VzLXMtYS5wbmc { background: url('./img/ip-telecom-servicos-de-telecomunicacoes-s-a.png') no-repeat 0 0; background-position: center; }
.YmxhZGUucG5n { background: url('./img/blade.png') no-repeat 0 0; background-position: center; }
.Y3k0Z2F0ZS5wbmc { background: url('./img/cy4gate.png') no-repeat 0 0; background-position: center; }
.aGFpZXItY29zbW8taW90LWVjb3N5c3RlbS10ZWNob2xvZ3ktY28tbHRkLnBuZw { background: url('./img/haier-cosmo-iot-ecosystem-techology-co-ltd.png') no-repeat 0 0; background-position: center; }
.dGhpbmtwb3J0LWdtYmgucG5n { background: url('./img/thinkport-gmbh.png') no-repeat 0 0; background-position: center; }


.wrapper {
  display: flex;
  justify-content: space-between;
}

.main {
  width: 85%;
}

@media (min-width:680px) {
  .sidebar {
    width: 20%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
  }
  .member-list {
    display: none;
  }
}

@media (max-width:740px) {

  .main {
    width: 100%;
  }
  .sidebar {
    display: none;
  }

  .member-list {
    display: block;
  }

  .member-logos {display: none}
}


.active-control img {
  opacity: 1;
}

.inactive-control img {
  opacity: 0.3;
}

.inactive-control {
  color: lightsteelblue !important;
}

.remove {
  display: none !important;
}

.main .badge {
  cursor: pointer;

}
.main .badge:hover {
  background-color: lightgrey;
}

.box {
  border: solid 1px lightsteelblue;
  padding: 10px;
}

.footer {
  padding: 10px;
  position: fixed;
  bottom: 0;
  background-color: rgba(255,255,255,0.7);
  width: 100%;
  text-align: center;
  margin: auto;
}

.footer .badge:hover {
  background-color: white;
}